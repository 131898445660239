import type { ReactElement } from "react";
import * as t from "io-ts";

import { O } from "@scripts/fp-ts";
import type { ApiRedirect, ApiRedirectC } from "@scripts/generated/models/apiRedirect";
import type { EmailUnsubscribeData, EmailUnsubscribeDataC } from "@scripts/generated/models/emailCampaign";
import type {
  LoginPageData,
  LoginPageDataC,
  ResetPasswordData,
  ResetPasswordDataC,
  ResetPasswordMfaData,
  ResetPasswordMfaDataC,
} from "@scripts/generated/models/login";
import type { PrivateSite, PrivateSiteC } from "@scripts/generated/models/privateSite";
import type { ConfirmAccountData, ConfirmAccountDataC } from "@scripts/generated/models/user";
import * as V2Router from "@scripts/generated/routers/v2Router";
import type { Klass } from "@scripts/react/util/classnames";

import { format } from "../base";
import { noParams } from "../pageMeta";
import type { RouteMeta } from ".";

type PagesRouteMeta<PropsA, PropsO> = RouteMeta<PropsA, PropsO> & {
  title: () => string;
  descriptionMeta: () => string;
  bodyKlass: Klass;
  rootKlass: Klass;
};

export type PagesRouteWithRender<PropsA, PropsO> =
  PagesRouteMeta<PropsA, PropsO> & {
    render: (props: PropsA) => ReactElement<PropsA>;
  };

export const loginMatch: V2Router.BaseAuthControllerLoginRoute["match"] = V2Router.baseAuthControllerLoginRoute().match;
export const signupMatch: V2Router.BaseAuthControllerSignupRoute["match"] = V2Router.baseAuthControllerSignupRoute().match;

export const login = () => ({
  _tag: "login",
  propsCodec: V2Router.baseAuthControllerLoginCodecs.ssrInput,
  url: () => format(loginMatch, noParams),
  title: () => "Log In or Sign Up",
  descriptionMeta: () => "Log in to BondLink or create a new account.",
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<LoginPageData, t.OutputOf<LoginPageDataC>>;

export const resetPasswordMatch: V2Router.BaseAuthControllerConfirmPasswordLinkRoute["match"] = V2Router.baseAuthControllerConfirmPasswordLinkRoute().match;

const resetPasswordTitle = () => "Reset Password";
const resetPasswordDescription = () => "Reset your BondLink account password.";

export const resetPassword = () => ({
  _tag: "resetPassword",
  propsCodec: V2Router.baseAuthControllerConfirmPasswordLinkCodecs.ssrInput,
  url: () => format(resetPasswordMatch, noParams),
  title: resetPasswordTitle,
  descriptionMeta: resetPasswordDescription,
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<ResetPasswordData, t.OutputOf<ResetPasswordDataC>>;

export const mfaResetPasswordMatch: V2Router.BaseAuthControllerConfirmPasswordLinkMfaRoute["match"] = V2Router.baseAuthControllerConfirmPasswordLinkMfaRoute().match;

export const mfaResetPassword = () => ({
  _tag: "mfaResetPassword",
  propsCodec: V2Router.baseAuthControllerConfirmPasswordLinkMfaCodecs.ssrInput,
  url: () => format(mfaResetPasswordMatch, noParams),
  title: resetPasswordTitle,
  descriptionMeta: resetPasswordDescription,
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<ResetPasswordMfaData, t.OutputOf<ResetPasswordMfaDataC>>;

export const reviewTermsMatch: V2Router.BaseAuthControllerReviewTermsRoute["match"] = V2Router.baseAuthControllerReviewTermsRoute().match;

export const reviewTerms = () => ({
  _tag: "reviewTerms",
  propsCodec: V2Router.baseAuthControllerReviewTermsCodecs.ssrInput,
  url: () => format(reviewTermsMatch, noParams),
  title: () => "Review Terms",
  descriptionMeta: () => "Please review BondLink’s terms and conditions",
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<ApiRedirect, t.OutputOf<ApiRedirectC>>;

export const reviewBLPTermsMatch: V2Router.BaseAuthControllerReviewBLPTermsRoute["match"] = V2Router.baseAuthControllerReviewBLPTermsRoute().match;

export const reviewBLPTerms = () => ({
  _tag: "reviewBLPTerms",
  propsCodec: V2Router.baseAuthControllerReviewBLPTermsCodecs.ssrInput,
  url: () => format(reviewBLPTermsMatch, noParams),
  title: () => "Review Terms",
  descriptionMeta: () => "Please review the BondLink Primary terms and conditions",
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<ApiRedirect, t.OutputOf<ApiRedirectC>>;

export const accountInviteMatch: V2Router.BaseAuthControllerConfirmInviteRoute["match"] = V2Router.baseAuthControllerConfirmInviteRoute().match;

export const accountInvite = () => ({
  _tag: "accountInvite",
  propsCodec: V2Router.baseAuthControllerConfirmInviteCodecs.ssrInput,
  url: () => format(accountInviteMatch, noParams),
  title: () => "Confirm your BondLink account",
  descriptionMeta: () => "Confirm your BondLink account.",
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<ConfirmAccountData, t.OutputOf<ConfirmAccountDataC>>;

export const emailUnsubscribeMatch: V2Router.BaseEmailUnsubscribeByHashRoute["match"] = V2Router.baseEmailUnsubscribeByHashRoute().match;

export const emailUnsubscribe = (hash: string) => ({
  _tag: "emailUnsubscribe",
  propsCodec: V2Router.baseEmailUnsubscribeByHashCodecs.ssrInput,
  url: () => format(emailUnsubscribeMatch, { hash }),
  title: () => "Manage Email Preferences",
  descriptionMeta: () => "Manage Email Preferences",
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<EmailUnsubscribeData, t.OutputOf<EmailUnsubscribeDataC>>;

export const privateSiteMatch: V2Router.BaseSsrShimPrivateSiteRoute["match"] = V2Router.baseSsrShimPrivateSiteRoute().match;

export const privateSite = () => ({
  _tag: "privateSite",
  propsCodec: V2Router.baseSsrShimPrivateSiteCodecs.ssrInput,
  url: () => format(privateSiteMatch, {}),
  title: () => "Private Site",
  descriptionMeta: () => "This site is under construction and not yet available to the public.",
  bodyKlass: O.some("minimal-layout"),
  rootKlass: O.none,
}) as const satisfies PagesRouteMeta<PrivateSite, t.OutputOf<PrivateSiteC>>;

export const notFound = {
  _tag: "NotFound",
  propsCodec: t.unknown,
  url: () => "/",
  title: () => "Not Found",
  descriptionMeta: () => "Not Found",
  bodyKlass: O.none,
  rootKlass: O.none,
} as const satisfies PagesRouteMeta<unknown, t.OutputOf<t.UnknownC>>;

export type IssuerSitesPageMeta = typeof notFound
  | ReturnType<typeof login>
  | ReturnType<typeof resetPassword>
  | ReturnType<typeof mfaResetPassword>
  | ReturnType<typeof reviewTerms>
  | ReturnType<typeof accountInvite>
  | ReturnType<typeof emailUnsubscribe>
  | ReturnType<typeof privateSite>;

export type IssuerSitesPageTagU = IssuerSitesPageMeta["_tag"];
